import { unixToDateTimeString } from "@/js/general"

const ACTION_MAP = new Map([
  ["", "current"],
  ["mod", "modified"],
]);

const VERSION_COLUMN = {
  name: "Version",
  key: "version",
  formatter: (v) => v,
  sortable: true,
  width: 85,
};

const ACTION_COLUMN = {
  name: "Action",
  key: "action",
  formatter: (value) => ACTION_MAP.get(value) || "",
  width: 100
};

const CREATED_BY_COLUMN = {
  name: "Created by",
  key: "createdBy",
  formatter: (v) => v,
  sortable: true,
};

const CREATE_DATE_COLUMN = {
  name: "Created date",
  key: "createDate",
  formatter: unixToDateTimeString,
  sortable: true,
};

const MODIFIED_BY_COLUMN = {
  name: "Modified by",
  key: "modifiedBy",
  formatter: (v) => v,
  sortable: true,
};

const MODIFY_DATE_COLUMN = {
  name: "Modify date",
  key: "modifyDate",
  formatter: unixToDateTimeString,
  sortable: true,
};

const COMMENT_COLUMN = {
  name: "Comment",
  key: "comment",
  formatter: (v) => v,
  sortable: true,
};

export const JOURNAL_INFO_COLS = [
  VERSION_COLUMN,
  CREATED_BY_COLUMN,
  CREATE_DATE_COLUMN,
  MODIFIED_BY_COLUMN,
  MODIFY_DATE_COLUMN,
  COMMENT_COLUMN,
];

export const JOURNAL_INFO_COLS_FOR_TABLE_VIEW = [
  VERSION_COLUMN,
  ACTION_COLUMN,
  MODIFIED_BY_COLUMN,
  MODIFY_DATE_COLUMN,
  COMMENT_COLUMN,
].map(({ key, ...col }) => ({ key:  `history.${key}`, ...col }));

export const useTableHistory = () => {
  const getHistoryCols = (itemHistory) => {
    return JOURNAL_INFO_COLS.filter((col) => Boolean(itemHistory[col.key]))
  };

  return {
    getHistoryCols,
  }
}
